//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft } from '@reduxjs/toolkit';

/**
 * This reducer will reset current quote state to initial state .
 *
 * @param {Draft<State>} state - The current state
 */
const removeAssessmentFromQuoteReducer = (state: Draft<State>) => {
    state.quote = {
        ...state.quote,
        area: null,
        available: undefined,
        polygon: null,
        height: undefined,
        osm_id: undefined
    };
};

export default removeAssessmentFromQuoteReducer;
