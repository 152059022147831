import { GraphQLAuthMode } from '@aws-amplify/core/internals/utils';

export const AMPLIFY_CONFIG = {
    API: {
        GraphQL: {
            endpoint: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQLENDPOINT || '',
            region: process.env.NEXT_PUBLIC_AWS_REGION,
            defaultAuthMode: 'iam' as GraphQLAuthMode
        }
    },
    Auth: {
        Cognito: {
            identityPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITYPOOLID || '',
            userPoolClientId: process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOLWEBCLIENTID || '',
            userPoolId: process.env.NEXT_PUBLIC_AWS_COGNITO_USERPOOLID || '',
            allowGuestAccess: true
        }
    }
};
