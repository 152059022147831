//interfaces
import { Question } from '@components/lib/interfaces/model/models/Question';
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current question state if there are changes to the question
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<Question>} action - The payload for question
 */
const setQuestionReducer = (state: Draft<State>, action: PayloadAction<Question>) => {
    state.question = action.payload;
    if (
        state.question &&
        state.question.validated &&
        state.question.value &&
        !state.quote[state.question.input]
    ) {
        state.quote[state.question.input] = state.question.value as never;
    }
};

export default setQuestionReducer;
