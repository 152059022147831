//library
import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { createWrapper } from 'next-redux-wrapper';

//slice
import reducer from '@components/store/slices/simulator/simulatorSlice';

const store = configureStore({
    reducer: {
        simulator: reducer
    }
});

export const makeStore = () => store;

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const wrapper = createWrapper(makeStore);
