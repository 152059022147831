//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current total state when configure the question for the first time
 * The total question will be different for commercial industrial and residential
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<number>} action - The payload
 */
const setTotalReducer = (state: Draft<State>, action: PayloadAction<number>) => {
    state.total = action.payload;
};

export default setTotalReducer;
