//interfaces
import { AddressData } from '@components/lib/interfaces/model/models/AddressData';
import { AreaData } from '@components/lib/interfaces/model/models/AreaData';
import { Contact } from '@components/lib/interfaces/model/models/Contact';
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current quote state if there are changes to address data
 * It is called when user change their address in home page
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<AddressData>} action - The payload for question
 */
const setQuoteReducer = (
    state: Draft<State>,
    action: PayloadAction<AddressData | AreaData | Record<string, Contact> | Record<string, string>>
) => {
    state.quote = { ...state.quote, ...action.payload };
};

export default setQuoteReducer;
