//interfaces
import { Error } from '@components/lib/interfaces/model/models/Error';
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current error state when there is error found.
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<string>} action - The payload
 */
const setErrorReducer = (state: Draft<State>, action: PayloadAction<Error>) => {
    state.error = action.payload;
};

export default setErrorReducer;
