//type
import { ListAssessments } from '@components/lib/interfaces/config/graphql/API';

//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update the assessments
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<number>} action - The payload
 */
const setAssessmentsReducer = (state: Draft<State>, action: PayloadAction<ListAssessments>) => {
    state.assessments = action.payload;
};

export default setAssessmentsReducer;
