//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft } from '@reduxjs/toolkit';

/**
 * This reducer will update current state when user click next button.
 *
 * @param {Draft<State>} state - The current state
 */
const nextReducer = (state: Draft<State>) => {
    if (state.step != state.total) {
        state.step += 1;
        state.direction = 'continue';
    }
};

export default nextReducer;
