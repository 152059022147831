//type
import { State } from '@components/store/interfaces/slices/simulator/state';

/**
 * Represents the initial state for the store.
 * @type {object} State
 * @property {object} assessments - Assessment-related information.
 * @property {string} assessments.__typename - The type name for assessments (e.g., 'ListAssessments').
 * @property {number} assessments.total - Total number of assessments.
 * @property {number} assessments.current_page - Current page number for assessments.
 * @property {number} assessments.max_page - Maximum page number for assessments.
 * @property {Array} assessments.assessments - An array containing assessment data.
 * @property {object} quote - Information related to a quote.
 * @property {number|null} quote.lat - Latitude for the quote.
 * @property {number|null} quote.lng - Longitude for the quote.
 * @property {string|null} quote.area - Area information for the quote.
 * @property {string|null} quote.address - Address information for the quote.
 * @property {string|null} quote.type - Type information for the quote.
 * @property {string|null} quote.polygon - Polygon information for the quote.
 * @property {number} total - Total count.
 * @property {number} step - Current step in the process.
 * @property {string} direction - Direction for the process ('next' or back).
 * @property {object} question - Information related to a question.
 * @property {string} question.label - Label for the question.
 * @property {string} question.input - Input type for the question.
 * @property {string} question.type - Type of question (e.g., 'map').
 * @property {string} set - Set information (e.g., 'desktop').
 * @property {object} offer - Offer information.
 * @property {boolean} loading - Indicates if data is currently being loaded.
 * @property {string} error - Error message if any.
 * @property {object} popup - Popup-related information.
 * @property {boolean} popup.buildingType - Indicates if the buildingType popup is visible.
 * @property {boolean} popup.invalidAddress - Indicates if the invalid address popup is visible.
 */

/**
 * The initial state for the store.
 * @type {State}
 */
export const initialState: State = {
    assessments: {
        __typename: 'ListAssessments',
        total: 0,
        current_page: 0,
        max_page: 0,
        assessments: []
    },
    quote: {
        lat: null,
        lng: null,
        area: null,
        address: null,
        type: null,
        polygon: null
    },
    total: 8,
    step: 0,
    direction: 'continue',
    question: { label: 'search', input: 'address', type: 'map' },
    set: 'desktop',
    offer: null,
    loading: {
        getOffer: false,
        confirmGetOffer: false
    },
    loadingPercentage: 0,
    error: null,
    popup: {
        buildingType: false,
        invalidAddress: false,
        measureVideoTutorial: false,
        help: false,
        error: false
    },
    isDrawing: false
};
