//type
import {
    GetOfferMutation,
    GetOfferMutationVariables,
    Quote
} from '@components/lib/interfaces/config/graphql/API';

//library
import { Client, GraphQLResult } from 'aws-amplify/api';

//code
import { getOffer } from '@components/lib/config/graphql/mutations';

/**
 * Get offer data
 *
 * @param {Client} clientApi - Amplify API.
 * @param {GetOfferMutationVariables} variables - The query variables.
 * @returns {Promise<GraphQLResult<GetOfferMutation>>} - The response
 */
const getOfferMutation = async (
    clientApi: Client,
    variables: GetOfferMutationVariables
): Promise<Quote> => {
    const response: GraphQLResult<GetOfferMutation> = (await clientApi.graphql({
        query: getOffer,
        variables
    })) as GraphQLResult<GetOfferMutation>;

    return response.data.getOffer;
};
export default getOfferMutation;
