//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft } from '@reduxjs/toolkit';
import { initialState } from '@components/store/slices/simulator/state';

/**
 * This reducer will update current state when user click back button.
 *
 * @param {Draft<State>} state - The current state
 */
const backReducer = (state: Draft<State>) => {
    if (state.step >= 1) {
        state.step -= 1;
        state.direction = 'back';
    }
    // reset the assessments if backed into the search step
    if (state.step === 1) {
        state.assessments = initialState.assessments;
    }
};

export default backReducer;
