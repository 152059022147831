//interfaces
import { Assessment } from '@components/lib/interfaces/config/graphql/API';
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current quote state after receive assessment result.
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<Assessment>} action - The payload
 */
const addAssessmentToQuoteReducer = (state: Draft<State>, action: PayloadAction<Assessment>) => {
    const { building } = action.payload;
    state.quote = {
        ...state.quote,
        area: building.area.toString(),
        available: building.available?.toString(),
        polygon: building.polygon,
        utilities: state.quote.utilities || building.utilities?.toString(),
        storeys: state.quote.storeys || building.storeys || undefined,
        height: building.height || undefined,
        osm_id: building.osm_id || undefined
    };
};

export default addAssessmentToQuoteReducer;
