//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current set state.
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<boolean>} action - The payload
 */
const setIsDrawingReducer = (state: Draft<State>, action: PayloadAction<boolean>) => {
    state.isDrawing = action.payload;
};

export default setIsDrawingReducer;
