import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationENSG from '@public/locales/en-sg/translation.json';
import translationENMY from '@public/locales/en-my/translation.json';

const resources = {
    'en-SG': {
        translation: translationENSG
    },
    'en-MY': {
        translation: translationENMY
    }
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'en-SG'
});

export default i18n;
