//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current set state.
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<'desktop' | 'mobile'>} action - The payload
 */
const setSetReducer = (state: Draft<State>, action: PayloadAction<'desktop' | 'mobile'>) => {
    state.set = action.payload;
};

export default setSetReducer;
