//interfaces
import { PopUpData } from '@components/lib/interfaces/model/models/PopUpData';
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will update current popup state when there is event for open / close modal dialog
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<PopUpData>} action - The payload
 */
const setTooglePopupReducer = (state: Draft<State>, action: PayloadAction<PopUpData>) => {
    const { popup, open } = action.payload;
    state.popup[popup] = open || false;
};

export default setTooglePopupReducer;
