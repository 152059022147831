/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum COUNTRY_CODE {
  SG = "SG",
  MY = "MY",
}


export type VendeeInput = {
  name?: string | null,
  family_name?: string | null,
  email?: string | null,
  address: string,
  address_components: Array< AddressComponentInput | null >,
  company?: string | null,
  phone?: string | null,
  timeframe?: string | null,
  referral?: string | null,
  building: BuildingInput,
};

export type AddressComponentInput = {
  long_name: string,
  short_name: string,
  types: Array< string | null >,
};

export type BuildingInput = {
  type: BUILDING_TYPE,
  energy?: BUILDING_ENERGY | null,
  lng: number,
  lat: number,
  height?: number | null,
  storeys?: number | null,
  utilities?: number | null,
  osm_id?: number | null,
  material?: ROOF_MATERIAL | null,
  retailer?: RETAILER | null,
  meter_box?: METER_BOX | null,
  daytime_consumption: DAYTIME_CONSUMPTION,
  area: number,
  available?: number | null,
  polygon: Array< PolygonInput >,
};

export enum BUILDING_TYPE {
  LANDED_HOUSE = "LANDED_HOUSE",
  CONDOMINIUM = "CONDOMINIUM",
  HDB_FLAT = "HDB_FLAT",
  RENTED_COMMERCIAL_PROPERTY = "RENTED_COMMERCIAL_PROPERTY",
  OWNED_COMMERCIAL_PROPERTY = "OWNED_COMMERCIAL_PROPERTY",
}


export enum BUILDING_ENERGY {
  AVERAGE = "AVERAGE",
  INTENSIVE = "INTENSIVE",
  LOW = "LOW",
}


export enum ROOF_MATERIAL {
  METAL = "METAL",
  TILES_SIMPLE = "TILES_SIMPLE",
  TILES_COMPLEX = "TILES_COMPLEX",
  TILES = "TILES",
  CONCRETE = "CONCRETE",
  MIXED = "MIXED",
  OTHER = "OTHER",
}


export enum RETAILER {
  SPGROUP = "SPGROUP",
  GENECO = "GENECO",
  KEPPEL_ELECTRIC = "KEPPEL_ELECTRIC",
  SENOKO_ENERGY = "SENOKO_ENERGY",
  UNION_POWER = "UNION_POWER",
  TUAS_POWER_SUPPLY = "TUAS_POWER_SUPPLY",
  SEMBCORP_POWER = "SEMBCORP_POWER",
  DIAMOND_ELECTRIC = "DIAMOND_ELECTRIC",
  PACIFIC_LIGHT_ENERGY = "PACIFIC_LIGHT_ENERGY",
  SUNSEAP_ENERGY = "SUNSEAP_ENERGY",
  OTHER = "OTHER",
}


export enum METER_BOX {
  SINGLE_PHASE = "SINGLE_PHASE",
  THREE_PHASE = "THREE_PHASE",
  USER_UNSURE = "USER_UNSURE",
}


export enum DAYTIME_CONSUMPTION {
  MORNING_AND_NIGHT = "MORNING_AND_NIGHT",
  EQUALLY_THROUGHOUT_DAY = "EQUALLY_THROUGHOUT_DAY",
  WEEKDAY_OFFICE = "WEEKDAY_OFFICE",
  WEEKDAY_OFFICE_EXTENDED = "WEEKDAY_OFFICE_EXTENDED",
  EVERYDAY_OFFICE = "EVERYDAY_OFFICE",
  EVERYDAY_OFFICE_EXTENDED = "EVERYDAY_OFFICE_EXTENDED",
  TWENTYFOUR_SEVEN = "TWENTYFOUR_SEVEN",
  OTHER = "OTHER",
}


export type PolygonInput = {
  lng: number,
  lat: number,
};

export type Quote = {
  __typename: "Quote",
  installation: QuoteInstallation,
  savings: QuoteSavings,
  impact: QuoteImpact,
  roof_size: number,
  rtoOptions?:  Array<RtoOption > | null,
  rto?: boolean | null,
  code?: string | null,
};

export type QuoteInstallation = {
  __typename: "QuoteInstallation",
  roi: string,
  cost: HighLow,
  generated: string,
  size: string,
  lease: string,
};

export type HighLow = {
  __typename: "HighLow",
  high: string,
  low: string,
};

export type QuoteSavings = {
  __typename: "QuoteSavings",
  utilities: HighLow,
  ppa: HighLow,
};

export type QuoteImpact = {
  __typename: "QuoteImpact",
  cars: string,
  trees: string,
  co2: string,
};

export type RtoOption = {
  __typename: "RtoOption",
  cost: number,
  payment: number,
};

export type EmailInput = {
  email: string,
};

export type OfferInput = {
  code: string,
  product: PRODUCT,
  term?: TENDER_SELECTION | null,
  waitlist?: boolean | null,
};

export enum PRODUCT {
  RTO = "RTO",
  QC = "QC",
  PPA = "PPA",
  DP = "DP",
}


export enum TENDER_SELECTION {
  TEN_YEAR = "TEN_YEAR",
  FIVE_YEAR = "FIVE_YEAR",
}


export type ListAssessments = {
  __typename: "ListAssessments",
  total: number,
  current_page: number,
  max_page: number,
  assessments:  Array<Assessment >,
};

export type Assessment = {
  __typename: "Assessment",
  id: string,
  building: Building,
};

export type Building = {
  __typename: "Building",
  type: string,
  height?: number | null,
  storeys?: number | null,
  eui?: number | null,
  energy?: string | null,
  consumption?: number | null,
  osm_id?: number | null,
  roof?: string | null,
  material?: string | null,
  area: number,
  available?: number | null,
  irradiation?: number | null,
  utilities?: number | null,
  polygon:  Array<Polygon >,
};

export type Polygon = {
  __typename: "Polygon",
  lng: number,
  lat: number,
};

export type GetOfferMutationVariables = {
  country_code: COUNTRY_CODE,
  input: VendeeInput,
};

export type GetOfferMutation = {
  getOffer:  {
    __typename: "Quote",
    installation:  {
      __typename: "QuoteInstallation",
      roi: string,
      cost:  {
        __typename: "HighLow",
        high: string,
        low: string,
      },
      generated: string,
      size: string,
      lease: string,
    },
    savings:  {
      __typename: "QuoteSavings",
      utilities:  {
        __typename: "HighLow",
        high: string,
        low: string,
      },
      ppa:  {
        __typename: "HighLow",
        high: string,
        low: string,
      },
    },
    impact:  {
      __typename: "QuoteImpact",
      cars: string,
      trees: string,
      co2: string,
    },
    roof_size: number,
    rtoOptions?:  Array< {
      __typename: "RtoOption",
      cost: number,
      payment: number,
    } > | null,
    rto?: boolean | null,
    code?: string | null,
  },
};

export type EmailMutationVariables = {
  input: EmailInput,
};

export type EmailMutation = {
  email: string,
};

export type SelectOfferMutationVariables = {
  country_code: COUNTRY_CODE,
  input: OfferInput,
};

export type SelectOfferMutation = {
  selectOffer: string,
};

export type ListAssessmentsQueryVariables = {
  country_code: COUNTRY_CODE,
  page: number,
  lng: number,
  lat: number,
};

export type ListAssessmentsQuery = {
  listAssessments:  {
    __typename: "ListAssessments",
    total: number,
    current_page: number,
    max_page: number,
    assessments:  Array< {
      __typename: "Assessment",
      id: string,
      building:  {
        __typename: "Building",
        type: string,
        height?: number | null,
        storeys?: number | null,
        eui?: number | null,
        energy?: string | null,
        consumption?: number | null,
        osm_id?: number | null,
        roof?: string | null,
        material?: string | null,
        area: number,
        available?: number | null,
        irradiation?: number | null,
        utilities?: number | null,
        polygon:  Array< {
          __typename: "Polygon",
          lng: number,
          lat: number,
        } >,
      },
    } >,
  },
};
