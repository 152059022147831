//type
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { initialState } from '@components/store/slices/simulator/state';

/**
 * This reducer will reset current state.
 *
 * @returns {State} The initial state of the simulator.
 */
const resetReducer = (): State => {
    return initialState;
};

export default resetReducer;
