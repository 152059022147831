//library
import { useEffect } from 'react';
import { NextRouter } from 'next/router';
import { hotjar } from 'react-hotjar';

//code
import * as googleAnalytics from './googleAnalytics';

/**
 * Check and use analytics when change page
 * @param {NextRouter} router - The router
 * @returns {void}
 */
const useAnalytics = (router: NextRouter): void => {
    useEffect(() => {
        //hotjar
        if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
            hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_ID), 6);
        }

        //google analytics
        const handleRouteChange = (url: URL) => {
            googleAnalytics.pageview(url);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        // Clean up on unmount
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
};

export default useAnalytics;
