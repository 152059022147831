/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOffer = /* GraphQL */ `
    mutation GetOffer($country_code: COUNTRY_CODE!, $input: VendeeInput!) {
        getOffer(country_code: $country_code, input: $input) {
            installation {
                roi
                cost {
                    high
                    low
                }
                generated
                size
                lease
            }
            savings {
                utilities {
                    high
                    low
                }
                ppa {
                    high
                    low
                }
            }
            impact {
                cars
                trees
                co2
            }
            roof_size
            rtoOptions {
                cost
                payment
            }
            rto
            code
        }
    }
`;
export const email = /* GraphQL */ `
    mutation Email($input: EmailInput!) {
        email(input: $input)
    }
`;
export const selectOffer = /* GraphQL */ `
    mutation SelectOffer($country_code: COUNTRY_CODE!, $input: OfferInput!) {
        selectOffer(country_code: $country_code, input: $input)
    }
`;
