//interfaces
import { State } from '@components/store/interfaces/slices/simulator/state';

//library
import { Draft, PayloadAction } from '@reduxjs/toolkit';

/**
 * This reducer will set loading percentage
 *
 * @param {Draft<State>} state - The current state
 * @param {PayloadAction<number>} action - The payload
 */
const setLoadingPercentageReducer = (state: Draft<State>, action: PayloadAction<number>) => {
    state.loadingPercentage = action.payload;
};

export default setLoadingPercentageReducer;
